$purp: #412596;
$purp-light: #9e80f9;
$purp-light-transparent: #9674f999;
$purp-light-transparent-25: #ab8ffd44;
$green-light-transparent: #84ff5e25;

.App {
  cursor: pointer;
  inset: 0;
  position: fixed;
}

.landing-page {
  margin: auto;
  overflow: hidden;
  position: fixed;
  inset: 0;
  z-index: 1000;

  svg {
    > path {
      width: 100px;
      height: 100px;
    }
    .svg-link-tspan {
      fill: white;
      font-weight: 400;
      transition: fill 0.1s;
    }

    &:hover {
      .svg-link-tspan {
        fill: $purp-light;
        text-decoration: underline;
      }
    }
  }
}

.card-title {
  margin-bottom: 4px;
  font-weight: 700;
}

audio {
  &::-webkit-media-controls-play-button,
  &::-webkit-media-controls-panel {
    transition: all 1s;
    background-color: rgb(255, 123, 0);
  }
  &.nightmode {
    &::-webkit-media-controls-play-button,
    &::-webkit-media-controls-panel {
      background-color: $purp-light;
    }
  }
}
.resume-divider {
  width: 100%;
  height: 1px;
  background: $purp-light;
  margin: 32px 0;
  mix-blend-mode: hard-light;
  z-index: 2;
}

.hor-list {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(2, 1fr);
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-bottom: 32px;

  > li {
    margin: 0;
    background: $green-light-transparent;
    padding: 16px;
    border-radius: 8px;
  }
}

.logoWrapper {
  position: fixed;
  overflow: visible;
  bottom: 0;
  left: 10vh;
  top: calc(50vh - 200px);
  position: absolute;
  transition: opacity 5s ease;
  z-index: 2;

  @media (min-width: 1280px) {
    top: calc(50vh - 40px);
  }
}

.logoComponent {
  position: absolute;
  overflow: visible;
  max-width: 400px;
  animation-name: spin;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 10s;
  transform-style: preserve-3d;
}

.logo1 {
  width: calc(32px + 12vw);
}

.logo2 {
  width: calc(32px + 9vw);
}

h2 {
  margin-top: 40px;
  margin-bottom: 10px;
}

p {
  line-height: 1.75rem;
}

.App-gg-bridge {
  position: fixed;
  bottom: -10px;
  right: -350px;
  height: 40vh;
  max-height: 228px;
  opacity: 0;
  z-index: 4;
  pointer-events: none;
  transition:
    opacity 1s,
    right 1s;
}

.App-gg-bridge-opaque {
  right: 0;
  opacity: 1;
}

.homeInfoContainer {
  position: fixed;
  color: #111;
  font-size: 20px;
  width: calc(22vw + 22vh);
  bottom: 16vh;
  min-width: 360px;

  right: calc(-5vw - 7.5vh + 100px);
  transform: translateX(-50%);
  transition: opacity 400ms ease;
  transition-delay: 1.5s;
  z-index: 3;
  opacity: 0;

  @media (min-width: 1280px) {
    right: calc(-5vw - 7.5vh + 100px);
    transform: translateX(-50%);
    font-size: 24px;
  }

  &.show {
    opacity: 1;
  }
}

.planet-container {
  position: fixed;
  inset: 0;
  z-index: 2;
}

.planet1 {
  position: absolute;
  transition: opacity 500ms ease;
  transition-delay: 1s;
  transform: translateY(100vh);
  opacity: 0;

  @media (max-width: 767px) {
    top: unset !important;
    bottom: -50vw !important;
    left: -100px !important;
    right: -100px !important;

    > svg {
      width: calc(100vw + 200px) !important;
      height: calc(100vw + 200px) !important;
      max-width: unset !important;
    }
  }
}

.planet1_day {
  right: calc(-5vw - 7.5vh);
  bottom: calc(-20vw - 18vh);

  > svg {
    width: calc(45vw + 55vh);
    height: calc(45vw + 55vh);
  }

  &.unmounted {
    opacity: 0;
  }

  &.on {
    opacity: 1;
    animation: 1.25s ease-in 0.25s both sunrise;
  }

  &.off {
    opacity: 1;
    animation: 1s ease-in both sunset;
  }
}

.planet1_night {
  left: calc(-5vw - 7.5vh);
  bottom: calc(-10vw - 15vh);

  > svg {
    width: calc(30vw + 30vh);
    height: calc(30vw + 30vh);
  }

  &.unmounted {
    opacity: 0;
  }

  &.on {
    opacity: 1;
    animation: 1.25s ease-in 0.25s both nightfall;
  }

  &.off {
    opacity: 1;
    animation: 1s ease-in both nightset;
  }
}

@keyframes sunrise {
  0% {
    transform: translateY(100vh);
    filter: drop-shadow(0px 0px 0px rgb(255, 225, 0));
  }
  100% {
    transform: translateY(0);
    filter: drop-shadow(0px 0px 40px rgb(255, 123, 0));
  }
}

@keyframes sunset {
  0% {
    transform: translateY(0);
    filter: drop-shadow(0px 0px 40px rgb(255, 123, 0));
  }
  100% {
    transform: translateY(100vh);
    filter: drop-shadow(0px 0px 0px rgb(255, 225, 0));
  }
}

@keyframes nightfall {
  0% {
    transform: translateY(100vh);
    filter: drop-shadow(0px 0px 0px $purp-light-transparent);
  }
  100% {
    transform: translateY(0);
    filter: drop-shadow(0px 0px 48px $purp-light-transparent);
  }
}

@keyframes nightset {
  0% {
    transform: translateY(0);
    filter: drop-shadow(0px 0px 48px $purp-light-transparent);
  }
  100% {
    transform: translateY(100vh);
    filter: drop-shadow(0px 0px 0px $purp-light-transparent);
  }
}

.nameTitle {
  position: absolute;
  left: 24px;
  right: 24px;
  top: 24px;
  width: calc(100% - 48px);
  transition:
    fill 3s ease,
    opacity 1s ease;
  z-index: 2;
  mix-blend-mode: multiply;
  max-height: 200px;
  font-family: "nova-mono", "Helvetica Neue", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", sans-serif;

  &.night {
    mix-blend-mode: difference;
  }
}

.highlightedChar_day {
  fill: #002d19;
}

.highlightedChar_night {
  fill: #6cfc9e;
  z-index: 100;
}

.hoverableHomeItem {
  display: flex;
  // align-items: center;
  gap: 8px;
  min-height: 16px;
}

.star {
  position: absolute;
  background: white;
  box-shadow: 0 0 1px 1px $purp-light-transparent-25;
  border-radius: 50%;
  animation: 4s ease-in-out infinite alternate star;

  &.star_disco {
    box-shadow: 0 0 1px 5px $purp-light-transparent-25;
    animation: 4s ease-in-out infinite alternate star-disco;
  }
}

@keyframes star {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.7);
  }
}

@keyframes star-disco {
  0% {
    transform: scale(1);
    filter: hue-rotate(0deg);
  }
  100% {
    transform: scale(2.2);
    filter: hue-rotate(360deg);
  }
}

li > ul > li {
  padding-bottom: 0px;
}

.resume-container {
  max-height: 100%;
  overflow-y: scroll;
  transition: opacity 1s ease;
  z-index: 4;
  position: relative;
  color: #eeeeee;
  transition-delay: 500ms;

  @media (min-width: 1280px) {
    padding-left: 600px;
  }
}

.resume-inner-container {
  margin: auto;
  margin-top: 80px;
  max-width: 800px;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 160px;

  @media (min-width: 1280px) {
    max-width: 980px;
    margin-top: 200px;
  }
}

#circle3 {
  // mix-blend-mode: difference;
}

.splitRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pill {
  border-radius: 16px;
  padding: 3px 8px;
  font-size: 10px;
  color: white;
  font-weight: 500;
  text-transform: uppercase;
  white-space: nowrap;
}

.location-pill {
  background: #84ff5e44;
  margin-left: 8px;
}

.interest-pill {
  background-color: #462d93;
  animation: rainbowFromRedToIndigo 7s ease-in-out infinite;

  &:nth-of-type(1) {
    animation-delay: 0s;
  }
  &:nth-of-type(2) {
    animation-delay: 1s;
  }
  &:nth-of-type(3) {
    animation-delay: 2s;
  }
  &:nth-of-type(4) {
    animation-delay: 3s;
  }
  &:nth-of-type(5) {
    animation-delay: 4s;
  }
  &:nth-of-type(6) {
    animation-delay: 5s;
  }
  &:nth-of-type(7) {
    animation-delay: 6s;
  }
}

@keyframes rainbowFromRedToIndigo {
  0% {
    background-color: #e8141666;
  }
  14% {
    background-color: #ffa50066;
  }
  28% {
    background-color: #faeb3666;
  }
  42% {
    background-color: #79c31666;
  }
  56% {
    background-color: #487de766;
  }
  70% {
    background-color: #4b369d66;
  }
  84% {
    background-color: #70369d66;
  }
  100% {
    background-color: #e8141666;
  }
}

@media (max-width: 1279px) {
  .App-gg-bridge {
    height: 30vh;
  }
}

@media (max-width: 767px) {
  .resume-container {
    padding-bottom: 240px;
    padding-top: 40px;
  }

  .splitRow {
    display: unset;
  }

  .homeInfoContainer {
    transition: color 1000ms ease-in-out;
    left: 40px;
    right: 40px;
    margin: auto;
    font-size: 18px;
    width: unset;
    max-width: 360px;
    bottom: 120px;
    transform: unset;
  }

  .nameTitle {
    left: 16px;
    right: 16px;
    top: 16px;
    width: calc(100% - 32px);
  }

  .App-gg-bridge {
    height: 20vh;
    max-height: 160px;
  }

  .logoWrapper {
    left: 0;
    right: 0;
    top: 24px;
    height: 50vh;
  }

  .logoComponent {
    &.logo1 {
      width: 200px;
      padding-top: 32px;
      padding-right: 32px;
    }

    &.logo2 {
      width: 140px;
    }
  }
}

button {
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  outline: inherit;
  text-shadow: inherit;
  padding: 0;
}

p {
  margin-block-start: 0.25em;
  margin-block-end: 0.25em;
}

a,
button {
  color: $purp;
  transition: color 50ms ease-in-out;
  font-weight: 600;

  &:hover {
    color: #010c73;
  }

  &.inverse {
    color: $purp-light;

    &:hover {
      color: #806eb8;
    }
  }
}

li {
  padding-bottom: 8px;
}

.App,
a,
button {
  cursor:
    url(./cursor.svg) 0 6,
    auto;
}

button:hover,
a:hover {
  text-decoration: underline;
  cursor:
    url(./cursor_pointer.png) 16 0,
    auto;
}

@keyframes spin {
  from {
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  to {
    -moz-transform: rotateY(-360deg);
    -ms-transform: rotateY(-360deg);
    transform: rotateY(-360deg);
  }
}

@keyframes backgroundAnim {
  0% {
    filter: hue-rotate(45deg);
  }
  100% {
    filter: hue-rotate(210deg);
  }
}

.App-background {
  inset: 0;
  position: absolute;
  filter: hue-rotate(210deg);
  transition: 500ms opacity ease-in-out;
  animation: 10s linear backgroundAnim;

  &.on {
    z-index: 1;
    transition-delay: 500ms;
    opacity: 1;
  }

  &.off {
    opacity: 0;
    transition-delay: 700ms;
  }
}

.App-background_night {
  background-color: black;
}

.App-background_day {
  background: linear-gradient(
    168deg,
    hsl(337.38, 100%, 88.04%) 0%,
    hsl(337.38, 100%, 88.04%) 1%,
    hsl(337.4, 100%, 88.18%) 1%,
    hsl(337.4, 100%, 88.18%) 2%,
    hsl(337.43, 100%, 88.32%) 2%,
    hsl(337.43, 100%, 88.32%) 3%,
    hsl(337.46, 100%, 88.46%) 3%,
    hsl(337.46, 100%, 88.46%) 4%,
    hsl(337.48, 100%, 88.59%) 4%,
    hsl(337.48, 100%, 88.59%) 5%,
    hsl(337.51, 100%, 88.73%) 5%,
    hsl(337.51, 100%, 88.73%) 6%,
    hsl(337.53, 100%, 88.87%) 6%,
    hsl(337.53, 100%, 88.87%) 7%,
    hsl(337.56, 100%, 89.01%) 7%,
    hsl(337.56, 100%, 89.01%) 8%,
    hsl(337.58, 100%, 89.14%) 8%,
    hsl(337.58, 100%, 89.14%) 9%,
    hsl(337.61, 100%, 89.28%) 9%,
    hsl(337.61, 100%, 89.28%) 10%,
    hsl(337.63, 100%, 89.41%) 10%,
    hsl(337.63, 100%, 89.41%) 11%,
    hsl(337.65, 100%, 89.55%) 11%,
    hsl(337.65, 100%, 89.55%) 12%,
    hsl(337.68, 100%, 89.68%) 12%,
    hsl(337.68, 100%, 89.68%) 13%,
    hsl(337.7, 100%, 89.82%) 13%,
    hsl(337.7, 100%, 89.82%) 14%,
    hsl(337.73, 100%, 89.95%) 14%,
    hsl(337.73, 100%, 89.95%) 15%,
    hsl(337.75, 100%, 90.08%) 15%,
    hsl(337.75, 100%, 90.08%) 16%,
    hsl(337.77, 100%, 90.21%) 16%,
    hsl(337.77, 100%, 90.21%) 17%,
    hsl(337.8, 100%, 90.35%) 17%,
    hsl(337.8, 100%, 90.35%) 18%,
    hsl(337.82, 100%, 90.48%) 18%,
    hsl(337.82, 100%, 90.48%) 19%,
    hsl(337.84, 100%, 90.61%) 19%,
    hsl(337.84, 100%, 90.61%) 20%,
    hsl(337.87, 100%, 90.74%) 20%,
    hsl(337.87, 100%, 90.74%) 21%,
    hsl(337.89, 100%, 90.87%) 21%,
    hsl(337.89, 100%, 90.87%) 22%,
    hsl(337.91, 100%, 91%) 22%,
    hsl(337.91, 100%, 91%) 23%,
    hsl(337.93, 100%, 91.13%) 23%,
    hsl(337.93, 100%, 91.13%) 24%,
    hsl(337.96, 100%, 91.26%) 24%,
    hsl(337.96, 100%, 91.26%) 25%,
    hsl(337.98, 100%, 91.39%) 25%,
    hsl(337.98, 100%, 91.39%) 26%,
    hsl(338, 100%, 91.52%) 26%,
    hsl(338, 100%, 91.52%) 27%,
    hsl(338.02, 100%, 91.64%) 27%,
    hsl(338.02, 100%, 91.64%) 28%,
    hsl(338.04, 100%, 91.77%) 28%,
    hsl(338.04, 100%, 91.77%) 29%,
    hsl(338.06, 100%, 91.9%) 29%,
    hsl(338.06, 100%, 91.9%) 30%,
    hsl(338.09, 100%, 92.02%) 30%,
    hsl(338.09, 100%, 92.02%) 31%,
    hsl(338.11, 100%, 92.15%) 31%,
    hsl(338.11, 100%, 92.15%) 32%,
    hsl(338.13, 100%, 92.28%) 32%,
    hsl(338.13, 100%, 92.28%) 33%,
    hsl(338.15, 100%, 92.4%) 33%,
    hsl(338.15, 100%, 92.4%) 34%,
    hsl(338.17, 100%, 92.53%) 34%,
    hsl(338.17, 100%, 92.53%) 35%,
    hsl(338.19, 100%, 92.65%) 35%,
    hsl(338.19, 100%, 92.65%) 36%,
    hsl(338.21, 100%, 92.78%) 36%,
    hsl(338.21, 100%, 92.78%) 37%,
    hsl(338.23, 100%, 92.9%) 37%,
    hsl(338.23, 100%, 92.9%) 38%,
    hsl(338.25, 100%, 93.03%) 38%,
    hsl(338.25, 100%, 93.03%) 39%,
    hsl(338.27, 100%, 93.15%) 39%,
    hsl(338.27, 100%, 93.15%) 40%,
    hsl(338.29, 100%, 93.27%) 40%,
    hsl(338.29, 100%, 93.27%) 41%,
    hsl(338.31, 100%, 93.39%) 41%,
    hsl(338.31, 100%, 93.39%) 42%,
    hsl(338.33, 100%, 93.52%) 42%,
    hsl(338.33, 100%, 93.52%) 43%,
    hsl(338.35, 100%, 93.64%) 43%,
    hsl(338.35, 100%, 93.64%) 44%,
    hsl(338.37, 100%, 93.76%) 44%,
    hsl(338.37, 100%, 93.76%) 45%,
    hsl(338.39, 100%, 93.88%) 45%,
    hsl(338.39, 100%, 93.88%) 46%,
    hsl(338.41, 100%, 94%) 46%,
    hsl(338.41, 100%, 94%) 47%,
    hsl(338.43, 100%, 94.12%) 47%,
    hsl(338.43, 100%, 94.12%) 48%,
    hsl(338.45, 100%, 94.24%) 48%,
    hsl(338.45, 100%, 94.24%) 49%,
    hsl(338.47, 100%, 94.36%) 49%,
    hsl(338.47, 100%, 94.36%) 50%,
    hsl(338.49, 100%, 94.48%) 50%,
    hsl(338.49, 100%, 94.48%) 51%,
    hsl(338.5, 100%, 94.6%) 51%,
    hsl(338.5, 100%, 94.6%) 52%,
    hsl(338.52, 100%, 94.72%) 52%,
    hsl(338.52, 100%, 94.72%) 53%,
    hsl(338.54, 100%, 94.84%) 53%,
    hsl(338.54, 100%, 94.84%) 54%,
    hsl(338.56, 100%, 94.96%) 54%,
    hsl(338.56, 100%, 94.96%) 55%,
    hsl(338.58, 100%, 95.08%) 55%,
    hsl(338.58, 100%, 95.08%) 56%,
    hsl(338.6, 100%, 95.2%) 56%,
    hsl(338.6, 100%, 95.2%) 57%,
    hsl(338.61, 100%, 95.31%) 57%,
    hsl(338.61, 100%, 95.31%) 58%,
    hsl(338.63, 100%, 95.43%) 58%,
    hsl(338.63, 100%, 95.43%) 59%,
    hsl(338.65, 100%, 95.55%) 59%,
    hsl(338.65, 100%, 95.55%) 60%,
    hsl(338.67, 100%, 95.66%) 60%,
    hsl(338.67, 100%, 95.66%) 61%,
    hsl(338.69, 100%, 95.78%) 61%,
    hsl(338.69, 100%, 95.78%) 62%,
    hsl(338.7, 100%, 95.9%) 62%,
    hsl(338.7, 100%, 95.9%) 63%,
    hsl(338.72, 100%, 96.01%) 63%,
    hsl(338.72, 100%, 96.01%) 64%,
    hsl(338.74, 100%, 96.13%) 64%,
    hsl(338.74, 100%, 96.13%) 65%,
    hsl(338.76, 100%, 96.24%) 65%,
    hsl(338.76, 100%, 96.24%) 66%,
    hsl(338.77, 100%, 96.36%) 66%,
    hsl(338.77, 100%, 96.36%) 67%,
    hsl(338.79, 100%, 96.47%) 67%,
    hsl(338.79, 100%, 96.47%) 68%,
    hsl(338.81, 100%, 96.59%) 68%,
    hsl(338.81, 100%, 96.59%) 69%,
    hsl(338.82, 100%, 96.7%) 69%,
    hsl(338.82, 100%, 96.7%) 70%,
    hsl(338.84, 100%, 96.81%) 70%,
    hsl(338.84, 100%, 96.81%) 71%,
    hsl(338.86, 100%, 96.93%) 71%,
    hsl(338.86, 100%, 96.93%) 72%,
    hsl(338.87, 100%, 97.04%) 72%,
    hsl(338.87, 100%, 97.04%) 73%,
    hsl(338.89, 100%, 97.15%) 73%,
    hsl(338.89, 100%, 97.15%) 74%,
    hsl(338.91, 100%, 97.27%) 74%,
    hsl(338.91, 100%, 97.27%) 75%,
    hsl(338.92, 100%, 97.38%) 75%,
    hsl(338.92, 100%, 97.38%) 76%,
    hsl(338.94, 100%, 97.49%) 76%,
    hsl(338.94, 100%, 97.49%) 77%,
    hsl(338.96, 100%, 97.6%) 77%,
    hsl(338.96, 100%, 97.6%) 78%,
    hsl(338.97, 100%, 97.71%) 78%,
    hsl(338.97, 100%, 97.71%) 79%,
    hsl(338.99, 100%, 97.83%) 79%,
    hsl(338.99, 100%, 97.83%) 80%,
    hsl(339, 100%, 97.94%) 80%,
    hsl(339, 100%, 97.94%) 81%,
    hsl(339.02, 100%, 98.05%) 81%,
    hsl(339.02, 100%, 98.05%) 82%,
    hsl(339.04, 100%, 98.16%) 82%,
    hsl(339.04, 100%, 98.16%) 83%,
    hsl(339.05, 100%, 98.27%) 83%,
    hsl(339.05, 100%, 98.27%) 84%,
    hsl(339.07, 100%, 98.38%) 84%,
    hsl(339.07, 100%, 98.38%) 85%,
    hsl(339.08, 100%, 98.49%) 85%,
    hsl(339.08, 100%, 98.49%) 86%,
    hsl(339.1, 100%, 98.6%) 86%,
    hsl(339.1, 100%, 98.6%) 87%,
    hsl(339.11, 100%, 98.71%) 87%,
    hsl(339.11, 100%, 98.71%) 88%,
    hsl(339.13, 100%, 98.82%) 88%,
    hsl(339.13, 100%, 98.82%) 89%,
    hsl(339.14, 100%, 98.92%) 89%,
    hsl(339.14, 100%, 98.92%) 90%,
    hsl(339.16, 100%, 99.03%) 90%,
    hsl(339.16, 100%, 99.03%) 91%,
    hsl(339.17, 100%, 99.14%) 91%,
    hsl(339.17, 100%, 99.14%) 92%,
    hsl(339.19, 100%, 99.25%) 92%,
    hsl(339.19, 100%, 99.25%) 93%,
    hsl(339.2, 100%, 99.36%) 93%,
    hsl(339.2, 100%, 99.36%) 94%,
    hsl(339.22, 100%, 99.47%) 94%,
    hsl(339.22, 100%, 99.47%) 95%,
    hsl(339.23, 100%, 99.57%) 95%,
    hsl(339.23, 100%, 99.57%) 96%,
    hsl(339.25, 100%, 99.68%) 96%,
    hsl(339.25, 100%, 99.68%) 97%,
    hsl(339.26, 100%, 99.79%) 97%,
    hsl(339.26, 100%, 99.79%) 98%,
    hsl(339.28, 100%, 99.89%) 98%,
    hsl(339.28, 100%, 99.89%) 99%,
    hsl(0, 0%, 100%) 99%,
    hsl(0, 0%, 100%) 100%
  );
}
